* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.map-con {
  width: 100%;
  height: 700px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
.black_opacity {
  width: 100%;
  height: 380px;
  background: black;
  opacity: 0.5;
}
.con-img_fi {
  width: 100%;
  height: 380px;
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: fixed;
  background-image: url("../../public/fin_con_img.png");
}
.con-img_fi h2 {
  display: flex;
  justify-content: space-around;
  align-items: center;
  width: 500px;
  position: absolute;
  color: #fff;
  font-size: 2.5rem;
}
iframe {
  max-width: 100%;
  max-height: 500px;
}
.c-container {
  width: 100%;
  height: 700px;
  padding: 20px 20rem;
  background: rgb(221, 219, 219);
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  background-repeat: no-repeat;
  background-position: fixed;
  background-size: 100% 100%;
  background-color: #f8f9fa;
}
.hor-con {
  width: 3.5px;
  background-color: #f8f9fa;
  height: 65%;
}
.con-main-df {
  border-radius: 8px;
  height: 560px;
  width: 750px;
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-around;
  align-items: center;
  background-color:#fff;
}

.con-input-field {
  width: 49%;
  height: 450px;
}
.contact-box {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: flex-start;
  margin: 16px;
  width: 95%;
}
.con-input-field h1 {
  color: #002347;
  text-align: center;
  font-size: 1.7rem;
}
.inputs-cont {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
}
.inputs-cont input {
  background: rgb(246, 245, 245);
  width: 90%;
  padding: 15px;
  font-size: 1rem;
  border: none;
  height: 50px;
  border-radius: 4px;
  outline-color: rgb(109, 142, 219);
}
.inputs-cont textarea {
  background: rgb(246, 245, 245);
  width: 90%;
  padding: 15px;
  font-size: 1rem;
  border: none;
  height: 100px;
  border-radius: 4px;
  outline-color: rgb(109, 142, 219);
}

.inputs-cont button {
  width: 42%;
  font-size: 1.1rem;
  border: none;
  border-radius: 3px;
  background-color: #002347;
  color: #fff;
  height: 50px;
  cursor: pointer;
}

.contact-details {
  width: 45%;
  height: 250px;
  display: flex;
  justify-content: start;
      align-items: stretch;
}
.contact-box {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  margin: 16px;
  width: 95%;
}
.add_icons{
  display: flex;
  width: 20%;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
}
.phone-icon {
  font-size: 1.5rem;
  margin: -23px 0 0 0 ;
  color: rgb(42, 60, 224);
}
.gmail-icon {
  margin: -60px 0 0 0 ;
  font-size: 2rem;
  color: red;
}
.map-icon {

  margin: -20px 0 0 0 ;
  font-size: 2.2rem;
  color: rgb(14, 68, 205);
}
.address_txt{
  display: flex;
  width: 94%;
  flex-direction: column;
  justify-content: space-around;
  align-items: start;
  
}
.address_txt a{
  text-decoration: none;
  color: rgb(89, 89, 89);
  font-size: 1.1rem;
  font-weight: bold;
}
.contact-box h1 {
  font-size: 2.5rem;
  color: rgb(0, 0, 0);
  text-align: center;
  margin-top: -30px;
  margin-left: 20px;
}

.map-iframe {
  margin: 50px 0;
  display: flex;
  justify-content: center;
}

.map-iframe .iframe {
  border-radius: 10px;
}

/* -------------------------------------------------Input Field Starts --------------------------------------------- */

/* -------------------------------------------------Input Field Ends --------------------------------------------- */
@media screen and (max-width: 1300px) {
  .c-container {
    width: 100%;
    height: 700px;
    padding: 20px 15rem;
    /* border: solid red; */

  }
  .con-main-df {
    height: 560px;
    width: 98%;
  }
}

@media screen and (max-width: 1100px) {
  .c-container {
    width: 100%;
    height: 700px;
    padding: 20px 12rem;
  }
  .black_opacity {
    height: 280px;
  }
  .con-img_fi {
    height: 280px;
    background-size: cover;
  }
  #contact {
    border: none;
  }

  .iframe {
    width: 80% !important;
    height: 300px;
  }
  .map-con {
    flex-direction: column;
  }

  
}
@media screen and (max-width: 950px) {
  .c-container {
    width: 100%;
    height: 700px;
    padding: 20px 7rem;
  }
}
@media screen and (max-width: 750px) {
  .c-container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    padding: 15px 2rem;
    width: 100%;
    height: 900px;
    /* flex-direction: column; */
  }
  .con-main-df{
    height: 900px;
  }
  .black_opacity {
    height: 250px;
  }
  .con-main-df{
    align-items: center;
    flex-direction: column-reverse;
    width: 98%;
    height: 820px;
  }
  .con-img_fi {
    justify-content: space-around;
    align-items: center;
    height: 250px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    background-image: url("../../public/fin_con_img.png");
  }

  .con-input-field {
    width: 95%;
    height: 500px;
    display: flex;
    justify-content: space-around;
    flex-direction: column;
    align-items: center;
  }
  
  .con-img_fi h2 {
    align-items: center;
    width: 250px;
    color: #fff;
    align-items: center;
    font-size: 1.6rem;
  }
  .inputs-cont{
    width: 90%;
  }
  .inputs-cont button {
    width: 60%;
  }
  /* .contact-details{
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
  } */
  .hor-con{
    height: 2px;
    width: 80%;
  }

  /* .contact-box {
    width: 100%;
    justify-content: space-around;
    align-items: center;
    border: solid rgb(108, 216, 83);
    border-radius: 15px;

  }
  .contact-box h3 {
    width: 100%;
    font-size: 16px;
    color: rgb(89, 89, 89);
  }
   */
  

  .contact-details {
    border-radius: 15px;
    border: solid rgb(108, 216, 83);
    width: 85%;
    height: 220px;
    display: flex;
    justify-content: start;
        align-items: stretch;
    /* border: solid red; */
  }

  .add_icons{
    display: flex;
    width: 20%;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
  }
  .phone-icon {
    font-size: 1.2rem;
    margin: -15px 0 0 0 ;
  
    color: rgb(42, 60, 224);
  }
  .gmail-icon {
    margin: -40px 0 0 0 ;
    /* margin: -4px 20px 0 -5px; */
    font-size: 1.7rem;
    color: red;
  }
  .map-icon {
    margin: 0px 0 0 0 ;
    font-size: 1.9rem;
    color: rgb(14, 68, 205);
  }
  .address_txt{
    display: flex;
    width: 80%;
    flex-direction: column;
    justify-content: space-around;
    align-items: start;
    
  }
  .address_txt a{
    text-decoration: none;
    color: rgb(89, 89, 89);
    font-size: 1rem;
  }
}
