.about {
    background-color: #f8f9fa;
  }

  
  .about-img-fi {
    position: relative;
    height: 400px;
    background-image: url(https://sandaltreesoft.com/static/media/aboutde.c197aba971f0afcea8c6.jpg);
    background-size: cover;
    background-position: center;
    z-index: 2;
  }
  
  .about-black-opacity {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.532);
    z-index: -1;
  }
  
  .about h2 {
    color: white !important;
    text-align: center;
    padding: 120px 0;
    font-size: 2.5rem;
    z-index: 1;
  }
  
  .about-section {
    padding: 3rem 4rem;
    max-width: 1500px;
    margin: 0 auto;
    background-color: #f8f9fa !important;
  }
  
  .about-content p {
    margin-bottom: 1.5rem;
    line-height: 1.6;
  }
  
  .about-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
  
  .about-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    flex: 1 1 calc(50% - 1.5rem); 
    box-sizing: border-box;
    text-align: center;
  }
  
  .about-card h3 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #002347;
  }
  
  .about-card p {
    margin: 0;
  }
  
  @media (max-width: 768px) {
    .about-card {
      flex: 1 1 100%; 
    }
  }
  .team-section {
    padding: 2rem 2rem;
    max-width: 1500px;
    margin: 0 auto;
    background-color: #f8f9fa;
  }
  
  .team-section h1 {
    text-align: center;
    font-size: 2.5rem;
    margin-bottom: 2rem;
    color: #002347 !important;
  }
  
  .team-card-container {
    display: flex;
    flex-wrap: wrap;
    gap: 1.5rem;
  }
  
  .team-card {
    background-color: #ffffff;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    flex: 1 1 calc(33.33% - 1.5rem); 
    box-sizing: border-box;
    text-align: center;
  }
  
  .team-card img {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    margin-bottom: 1rem;
  }
  
  .team-card h3 {
    margin-top: 0;
    font-size: 1.5rem;
    color: #002347;
  }
  
  .team-card p {
    margin: 0.5rem 0;
  }
  
  @media (max-width: 768px) {
    .team-card {
      flex: 1 1 100%; 
    }
  }