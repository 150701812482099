#services {
  color: #333;
  padding: 20px;
  background: #f9f9f9;
}

.service-section {
  text-align: center;
  margin-bottom: 40px;
}

.service-section h1 {
  font-size: 2.5rem;
  margin: 10px 0;
  color: #2c3e50;
}

.service-section p {
  font-size: 1.1rem;
  color: #7f8c8d;
}

.ser-main-con {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  padding: 20px;
}

.card {
  background: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.2s, box-shadow 0.2s;
  overflow: hidden;
  width: 300px;
  text-align: center;
}

.card img {
  width: 100%;
  height: auto;
}

.ser-container {
  padding: 20px;
}

.ser-container h2 {
  font-size: 1.5rem;
  color: #2c3e50;
  margin: 10px 0;
}

.card:hover {
  transform: translateY(-10px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

h2 {
  font-size: 2rem;
  color: #2c3e50;
  margin-bottom: 20px;
}
