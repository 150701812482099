.home-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 90%;
  background-color: #f8f9fa; 
  padding: 0 7rem;
}

.home-content {
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 1300px; 
  width: 100%;
  margin: 80px auto;
}

.home-text {
  flex: 1;
  padding-right: 2rem;
}

.home-heading {
  font-size: 2.5rem;
  font-weight: 700;
  color: #002347;
  margin-bottom: 1rem;
}

.home-subheading {
  font-size: 1.5rem;
  font-weight: 500;
  color: #343a40;
  margin-bottom: 1rem;
}

.home-description {
  font-size: 1rem;
  color: #495057;
  margin-bottom: 0.5rem;
}

.home-contact-link {
  display: inline-block;
  margin-top: 1rem;
  padding: 0.75rem 1.5rem;
  background-color: #002347;
  color: #ffffff;
  text-decoration: none;
  border-radius: 4px;
  font-weight: 500;
  transition: background-color 0.3s;
}

.home-contact-link:hover {
  background-color: #0056b3;
}

.home-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.home-img {
  max-width: 100%;
  height: auto;
}
.home-section {
  max-width: 1300px; 
  width: 100%;
  margin: 2rem auto; 
  padding: 1rem;
  background-color: #ffffff; 
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px; 
}
.section-content {
  display: flex;
  align-items: center;
}

.section-image {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-right: 2rem;
}

.section-img {
  max-width: 300px; 
  height: auto;
}

.section-text {
  flex: 2;
}

.section-heading {
  font-size: 2rem;
  font-weight: 700;
  color: #002347;
  margin-bottom: 1rem;
}

.section-description {
  font-size: 1rem;
  color: #495057;
}
