.serSection {
  text-align: center;
  padding: 20px 0 30px 0;
  width: 100%;
}

.sectionHeading {
  margin: 0px 0 15px 0;
  font-size: 3rem;
  width: 100%;
  display: grid;
  place-items: center;
}

.desc_p {
  height: 190px;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.column {
  width: 100%;
  padding: 0 1em 1em 1em;
  text-align: center;
}

.card {
  width: 100%;
  height: 100%;
  padding: 2em 1.5em;
  background: #ffffff;
  border-radius: 5px;
  box-shadow: 0 0 35px rgba(0, 0, 0, 0.12);
  cursor: pointer;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
}

.img {
  width: 100px;
  height: 100px;
}

h3 {
  font-size: 3rem;
  font-weight: 600;
  color: #002347;
  margin: 1em 0;
}

p {
  color: #575a7b;
  font-size: 15px;
  line-height: 1.6;
  letter-spacing: 0.03em;
}

.iconwrapper img {
  position: relative;
  margin: auto;
  height: 9em;
  width: 9em;
  color: #ffffff;
  border-radius: 50%;
  display: grid;
  place-items: center;
  transition: transform 0.5s, background-color 0.5s;
}

.card:hover {
  transform: translateY(-10px) scale(1.05);
  box-shadow: 0 0 45px rgba(0, 0, 0, 0.2);
}

@media screen and (min-width: 700px) {
  section {
    padding: 0 2em;
  }
  .column {
    flex: 0 50%;
    max-width: 50%;
  }
}

@media screen and (min-width: 992px) {
  section {
    padding: 1em 3em;
  }
  .column {
    flex: 0 0 33.33%;
    max-width: 33.33%;
  }
}
