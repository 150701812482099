.footer {
  background-color: #6cc7c7;
  padding: 2rem 1.5rem;
  color: #343a40;
  border-top: 1px solid #dee2e6;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
}

.footer-section {
  flex: 1;
  margin-right: 2rem;
}

.footer-about {
  flex: 2;
}

.footer-logo {
  font-size: 1.75rem;
  font-weight: 700;
  /* color: #343a40; */
  margin-bottom: 1rem;
}

.footer-address {
  font-size: 0.875rem;
}

.footer-address a {
  color: #343a40;
  text-decoration: none;
}

.footer-address a:hover {
  text-decoration: underline;
}

.address-icon {
  color: #007bff;
  margin-right: 0.5rem;
}

.footer-links {
  flex: 1;
}

.footer-links h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.footer-links ul {
  list-style: none;
  padding: 0;
}

.footer-links li {
  margin-bottom: 0.5rem;
}

.footer-links a {
  color: #343a40;
  text-decoration: none;
}

.footer-links a:hover {
  text-decoration: underline;
}

.footer-social {
  flex: 1;
}

.footer-social h3 {
  font-size: 1.25rem;
  margin-bottom: 1rem;
}

.social-icons {
  transition: 0.3s ease;
  display: flex;
  gap: 1rem;
}

.social-icon {
  font-size: 1.5rem;
}

.insta-footer{
  color: rgb(247, 67, 67);
}
.fb-footer{
  color: rgb(67, 97, 247);
}
.lin-footer{
  color: #0073b1;
}

.footer-bottom {
  text-align: center;
  padding: 1rem;
}

.footer-bottom p {
  margin: 0;
}
