.navbar {
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #ffffff;
    box-shadow: 0 4px 6px rgba(207, 207, 207, 0.1);
    transition: background-color 0.3s, box-shadow 0.3s;
    z-index: 1000;
}

.navbar-container {
    width: 100vw;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 7rem;
    height: 60px;
    margin: 0; /* Center the container */
}

.navbar-logo {
    display: flex;
    align-items: center;
    text-decoration: none;
    color: inherit;
}

.logo {
    height: 40px;
}

.brand-name {
    font-size: 1.25rem;
    font-weight: 600;
    margin-left: 0.75rem;
    color: inherit;
}

.navbar-menu {
    display: flex;
    gap: 1rem;
    margin-left: auto; /* Push the menu to the right */
}

.navbar-menu a {
    text-decoration: none;
    color: #343a40;
    font-weight: 500;
    padding: 0.3rem 1rem;
    transition: color 0.3s, background-color 0.3s;
    border-radius: 4px;
}

.navbar-menu a:hover {
    color: #ffffff;
    background-color: #6cc7c7; /* Accent color on hover */
}

.navbar-menu .active {
    color: #ffffff;
    background-color: #6cc7c7; /* Accent color on hover */
}

.navbar-toggle {
    display: none;
    background: none;
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: #343a40;
    transition: color 0.3s;
}

.navbar-toggle:hover {
    color: #007bff; /* Accent color on hover */
}

@media (max-width: 768px) {
    .navbar-menu {
        display: flex;
        flex-direction: column;
        position: absolute;
        top: 60px;
        right: 0;
        background-color: #ffffff;
        width: 100%;
        max-height: 0;
        overflow: hidden;
        transition: max-height 0.3s ease-in-out;
        box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        padding: 1rem;
        margin: 0;
    }

    .navbar-menu.active {
        max-height: 300px; /* Adjust based on the number of menu items */
        overflow: visible;
    }

    .navbar-toggle {
        display: block;
    }
}
